import type { FC } from 'react'

import { isNotUndefined } from '@hcr/utils'

import { useUserDetails } from '../../../../contexts'
import { useBenefitsQuery } from '../../../../hooks'
import { Path } from '../../../../models'
import { to } from '../../../../utils'
import { LayoutNavigationBackError404, LayoutNavigationBackError500 } from '../../../common'

import { createBenefitsGroup, LayoutOwnerBenefits, LayoutOwnerBenefitsLoading } from './common'

const BACK_NAVIGATION_PATH = to(Path.Owner)

const benefitsGroup = createBenefitsGroup()

export const OwnerBenefits: FC = () => {
  const userDetails = useUserDetails()
  const isVillasOwnerNoBenefit = Boolean(userDetails.data?.is_villas_owner) && Boolean(!userDetails.data?.has_benefits)

  const benefits = useBenefitsQuery(
    {
      isGoldOwner: Boolean(userDetails.data?.is_active_gold_owner) && Boolean(!userDetails.data?.has_benefits),
      isVillasOwner: Boolean(userDetails.data?.is_villas_owner) && Boolean(userDetails.data?.has_benefits),
      isVillasOwnerNoBenefit,
    },
    { enabled: userDetails.isSuccess }
  )

  if (userDetails.isError || benefits.isError) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (
    userDetails.isSuccess &&
    !isVillasOwnerNoBenefit &&
    isNotUndefined(userDetails.data) &&
    !userDetails.data.has_benefits
  ) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (userDetails.isSuccess && benefits.isSuccess) {
    return (
      <LayoutOwnerBenefits
        backNavigationPath={BACK_NAVIGATION_PATH}
        description={benefits.data.description}
        title={benefits.data.title}
      >
        {benefits.data.content.map(benefitsGroup)}
      </LayoutOwnerBenefits>
    )
  }

  return <LayoutOwnerBenefitsLoading backNavigationPath={BACK_NAVIGATION_PATH} />
}
