import CryptoJS from 'crypto-js'
import { useEffect } from 'react'
import { useAnalytics } from 'use-analytics'

import { useGlobalId } from '../../../hooks'

export const useGlobalIdTracking = () => {
  const analytics = useAnalytics()
  const globalId = useGlobalId()

  useEffect(() => {
    const trackGlobalIdChange = () => {
      if (!globalId) {
        return null
      }

      const hashedGlobalId = CryptoJS.SHA256(globalId).toString(CryptoJS.enc.Hex)

      return analytics.track('user-id', { userId: hashedGlobalId })
    }

    void trackGlobalIdChange()
  }, [analytics, globalId])
}
