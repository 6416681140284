import type { ReactNode } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'

import type { UserDetailsDto } from '@hcr/api/consumer'
import { isNotNull } from '@hcr/utils'

import { useIdToken, useUserDetailsQuery } from '../hooks'

interface UserDetailsContextProps {
  data: UserDetailsDto | undefined
  isError: boolean
  isPending: boolean
  isSuccess: boolean
}

const UserDetailsContext = createContext<UserDetailsContextProps | undefined>(undefined)

export const UserDetailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userDetails, setUserDetails] = useState<UserDetailsDto | undefined>(undefined)

  const { data, isError, isSuccess, isPending } = useUserDetailsData()

  useEffect(() => {
    setUserDetails(data)
  }, [data])

  return (
    <UserDetailsContext.Provider value={{ data: userDetails, isSuccess, isError, isPending }}>
      {children}
    </UserDetailsContext.Provider>
  )
}

export const useUserDetails = (): UserDetailsContextProps => {
  const context = useContext(UserDetailsContext)
  if (!context) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider')
  }
  return context
}

export const useUserDetailsData = () => {
  const idToken = useIdToken()
  const userDetails = useUserDetailsQuery(
    { idToken: String(idToken) },
    { enabled: isNotNull(idToken), retryOnMount: false }
  )

  return {
    data: userDetails.data,
    isSuccess: userDetails.isSuccess,
    isError: userDetails.isError,
    isPending: userDetails.isPending,
  }
}
