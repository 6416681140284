import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Stack, styled, TextField, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import type { FC } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import type { CountryDto } from '@hcr/api/consumer'
import { createUserDetailsQueryKey } from '@hcr/api/consumer'
import { Alert, Autocomplete, ButtonLoading, COLORS, LinkUnstyled, unit } from '@hcr/ui'
import { isNotUndefined, logger } from '@hcr/utils'

import { LINK_CONFIG, SENTRY_CONFIG } from '../../../configs'
import { useUserDetails } from '../../../contexts'
import { useCountriesQuery, useIdToken, useLocale, useUserUpdateMutation } from '../../../hooks'
import { Path } from '../../../models'
import { createCountryCodes, selectCountriesSortedByTranslationAsc, to } from '../../../utils'
import { LayoutNavigationBack, LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../common'

const BACK_NAVIGATION_PATH = to(Path.Account)

export const AccountEdit: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const locale = useLocale()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const countries = useCountriesQuery({ select: selectCountriesSortedByTranslationAsc })

  const userDetails = useUserDetails()

  const userUpdate = useUserUpdateMutation({ idToken: String(idToken) })

  const schema = z
    .strictObject({
      street_address: z
        .string()
        .trim()
        .min(1, { message: t('validation.required') })
        .max(100, { message: t('validation.characters-count-not-exceed', { count: 100 }) }),
      postal_code: z
        .string()
        .trim()
        .min(1, { message: t('validation.required') })
        .max(11, { message: t('validation.characters-count-not-exceed', { count: 11 }) }),
      city: z
        .string()
        .trim()
        .min(1, { message: t('validation.required') })
        .max(40, { message: t('validation.characters-count-not-exceed', { count: 40 }) }),
      country: z
        .string({ invalid_type_error: t('validation.required'), required_error: t('validation.required') })
        .trim()
        .min(1, { message: t('validation.required') }),
      country_code: z
        .string()
        .trim()
        .regex(/^\+[0-9]{1,3}$/, { message: t('validation.type-country-code') }),
      phone: z
        .string()
        .trim()
        .min(6, { message: t('validation.digits-count-at-least', { count: 6 }) })
        .max(15, { message: t('validation.digits-count-not-exceed', { count: 15 }) })
        .regex(/^[0-9]{1,}$/, { message: t('validation.type-number') }),
    })
    .refine(
      data => {
        if (data.country === 'Finland') {
          return /^[0-9]{5}$/.test(data.postal_code)
        }
        return true
      },
      {
        message: t('validation.digits-count-exact', { count: 5 }),
        path: ['postal_code'],
      }
    )

  type Schema = z.infer<typeof schema>

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      city: userDetails.data?.city,
      country: userDetails.data?.country,
      country_code: userDetails.data?.country_code,
      phone: userDetails.data?.phone,
      postal_code: userDetails.data?.postal_code,
      street_address: userDetails.data?.street_address,
    },
  })

  const onSubmit: SubmitHandler<Schema> = async data => {
    try {
      await userUpdate.mutateAsync(data)
      await queryClient.invalidateQueries({ queryKey: createUserDetailsQueryKey() })
      navigate(to(Path.Account))
    } catch {
      logger.error('Failed to edit the user:', data)
    }
  }

  if (countries.isError || userDetails.isError) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (countries.isSuccess && userDetails.isSuccess && isNotUndefined(userDetails.data)) {
    return (
      <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='text'>
        <Container>
          <Typography variant='headlineS'>{t('account-edit.contact-details')}</Typography>
          <Stack gap={unit(5)}>
            <Section>
              <Typography variant='uppercaseExtraSmall'>{t('account-edit.basic-info')}</Typography>
              <Inputs>
                <TextField
                  className={SENTRY_CONFIG.privacy.className}
                  value={userDetails.data.first_name}
                  label={t('account-edit.first-name')}
                  disabled
                />
                <TextField
                  className={SENTRY_CONFIG.privacy.className}
                  value={userDetails.data.last_name}
                  label={t('account-edit.last-name')}
                  disabled
                />
                <TextField
                  className={SENTRY_CONFIG.privacy.className}
                  value={userDetails.data.email}
                  label={t('account-edit.email-address')}
                  disabled
                />
                <Contact>
                  <Typography variant='bodyS'>
                    {t('account-edit.if-you-wish-to-change-your-name-or-email-please-contact')}{' '}
                    <LinkUnstyled to={`mailto:${LINK_CONFIG.customerSupport[locale.id]}`}>
                      <Typography variant='textLinkS'>{LINK_CONFIG.customerSupport[locale.id]}</Typography>
                    </LinkUnstyled>
                  </Typography>
                </Contact>
              </Inputs>
            </Section>
            <Section>
              <Typography variant='uppercaseExtraSmall'>{t('account-edit.address')}</Typography>
              <Inputs>
                <Controller
                  control={form.control}
                  name='street_address'
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextField
                      className={SENTRY_CONFIG.privacy.className}
                      value={value}
                      onChange={onChange}
                      label={t('account-edit.address')}
                      error={isNotUndefined(error)}
                      helperText={error?.message}
                      required
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='postal_code'
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextField
                      className={SENTRY_CONFIG.privacy.className}
                      inputMode='numeric'
                      value={value}
                      onChange={onChange}
                      label={t('account-edit.zip-code')}
                      error={isNotUndefined(error)}
                      helperText={error?.message}
                      placeholder='00000'
                      required
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='city'
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextField
                      className={SENTRY_CONFIG.privacy.className}
                      value={value}
                      onChange={onChange}
                      label={t('account-edit.city')}
                      error={isNotUndefined(error)}
                      helperText={error?.message}
                      required
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='country'
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Autocomplete
                      errorText={error?.message}
                      label={t('account-edit.country')}
                      getOptionLabel={option => countries.data[option as CountryDto]}
                      options={Object.keys(countries.data)}
                      isRequired
                      onChange={(_, value) => onChange(value)}
                      value={value}
                    />
                  )}
                />
              </Inputs>
            </Section>
            <Section>
              <Typography variant='uppercaseExtraSmall'>{t('account-edit.phone-number')}*</Typography>
              <FormFieldPhone>
                <Controller
                  control={form.control}
                  name='country_code'
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Autocomplete
                      className={SENTRY_CONFIG.privacy.className}
                      errorText={error?.message}
                      options={createCountryCodes()}
                      isRequired
                      onChange={(_, value) => onChange(value)}
                      value={value}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name='phone'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      className={SENTRY_CONFIG.privacy.className}
                      inputMode='tel'
                      value={value}
                      onChange={onChange}
                      required
                      error={isNotUndefined(error)}
                      helperText={error?.message}
                      placeholder={t('account-edit.phone-number')}
                    />
                  )}
                />
              </FormFieldPhone>
              <Typography variant='meta' paddingLeft={unit(3)}>
                {t('account-edit.required-information')}
              </Typography>
            </Section>
          </Stack>
          <Section>
            {userUpdate.isError && (
              <Alert
                severity='error'
                title={t('account-edit.error')}
                description={t('account-edit.sorry-we-encountered-an-error-while-saving-changes')}
              />
            )}
            <ButtonLoading
              onClick={form.handleSubmit(onSubmit)}
              isLoading={form.formState.isSubmitting}
              disabled={!form.formState.isDirty}
              variant='contained'
              color='primary'
            >
              {t('account-edit.save-changes')}
            </ButtonLoading>
            <Button
              component={LinkUnstyled}
              to={BACK_NAVIGATION_PATH}
              disabled={form.formState.isSubmitting}
              variant='text'
              color='primary'
            >
              {t('account-edit.cancel')}
            </Button>
          </Section>
        </Container>
      </LayoutNavigationBack>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}

const Container = styled(Stack)`
  background-color: ${COLORS.white};
  padding: ${unit(24)} ${unit(7)} ${unit(5)};
  min-height: 100vh;
  gap: ${unit(6)};
`

const Section = styled(Stack)`
  gap: ${unit(3)};
`

const Inputs = styled(Stack)`
  gap: ${unit(4)};
`

const FormFieldPhone = styled(Box)`
  display: grid;

  ${props => props.theme.breakpoints.down('md')} {
    grid-template-columns: 35% 65%;
  }

  ${props => props.theme.breakpoints.up('md')} {
    grid-template-columns: 25% 75%;
  }
`

const Contact = styled(Box)`
  background-color: ${COLORS.vaalea['60%']};
  padding: ${unit(4)} ${unit(5)};
`
