import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { encodeSearchParams, getJson } from '@hcr/api/shared'

import type { ErrorDto, OwnersDto } from '../../dtos'
import { OwnersDtoSchema } from '../../dtos'

type OwnersQueryParams =
  | {
      hasBenefits: boolean
      isContracted: true
      isGoldOwner: boolean
      isVillasOwner: boolean
      isVillasOwnerNoBenefits: boolean
    }
  | {
      isContracted: false
    }

export const createOwnersQueryKey = (params: OwnersQueryParams) => ['optimizely', 'owners', params]

export const createOwnersQuery =
  (baseUrl: string) =>
  <Data = OwnersDto>(params: OwnersQueryParams, options?: Partial<UseQueryOptions<OwnersDto, ErrorDto, Data>>) =>
    useQuery<OwnersDto, ErrorDto, Data>({
      ...options,
      queryKey: createOwnersQueryKey(params),
      queryFn: () =>
        getJson({
          schema: OwnersDtoSchema,
          url: `${baseUrl}/owners${
            params.isContracted
              ? encodeSearchParams({
                  hasBenefits: params.hasBenefits,
                  isContracted: true,
                  ownership: Object.entries({
                    week: !params.isVillasOwnerNoBenefits,
                    gold: params.isGoldOwner,
                    villas: params.isVillasOwner,
                    villasNoBenefit: params.isVillasOwnerNoBenefits,
                  })
                    .filter(([, value]) => value)
                    .map(([key]) => key),
                })
              : encodeSearchParams({ isContracted: false })
          }`,
        }),
    })
