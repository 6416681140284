import { useEffect } from 'react'
import { useAnalytics } from 'use-analytics'

export const useErrorPageTracking = ({ dataAnalyticsId }: { dataAnalyticsId: string }) => {
  const analytics = useAnalytics()

  useEffect(() => {
    const trackErrorPageErrorChange = () => {
      return analytics.track('error-page', { error_value: dataAnalyticsId })
    }

    void trackErrorPageErrorChange()
  }, [analytics, dataAnalyticsId])
}
