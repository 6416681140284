import type { DrawerProps as MuiDrawerProps } from '@mui/material'
import { Box, Drawer as MuiDrawer, useMediaQuery, useTheme } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'

import { unit } from '@hcr/ui'

type DrawerProps = PropsWithChildren & MuiDrawerProps

export const Drawer: FC<DrawerProps> = ({ children, ...props }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <MuiDrawer {...props} ModalProps={{ disableScrollLock: isDesktop, closeAfterTransition: true }}>
      <Box paddingTop={unit(7)} paddingX={unit(7)} paddingBottom={unit(11)}>
        {children}
      </Box>
    </MuiDrawer>
  )
}
