import { z } from 'zod'

export const MarketCodeDtoSchema = z.union([
  z.literal('ADP'),
  z.literal('BAR'),
  z.literal('BAS'),
  z.literal('BEN'),
  z.literal('CAD'),
  z.literal('CAF'),
  z.literal('CAR'),
  z.literal('CBB'),
  z.literal('CCO'),
  z.literal('CMP'),
  z.literal('CTS'),
  z.literal('CTS'),
  z.literal('FLE'),
  z.literal('FLY'),
  z.literal('GBM'),
  z.literal('GBU'),
  z.literal('GBW'),
  z.literal('GOL'),
  z.literal('GOL'),
  z.literal('GSO'),
  z.literal('GSP'),
  z.literal('GTD'),
  z.literal('GTI'),
  z.literal('GWE'),
  z.literal('HOL'),
  z.literal('MED'),
  z.literal('MED'),
  z.literal('MEM'),
  z.literal('MEM'),
  z.literal('NCL'),
  z.literal('NCO'),
  z.literal('NED'),
  z.literal('NEL'),
  z.literal('NEN'),
  z.literal('NFI'),
  z.literal('NUN'),
  z.literal('NVI'),
  z.literal('OTA'),
  z.literal('OTD'),
  z.literal('OTS'),
  z.literal('OWU'),
  z.literal('PET'),
  z.literal('STA'),
  z.literal('VIL'),
  z.literal('VIL'),
  z.literal('VIM'),
  z.literal('VIM'),
])

export type MarketCodeDto = z.infer<typeof MarketCodeDtoSchema>

export const isMarketCodeDto = (x: unknown): x is MarketCodeDto => MarketCodeDtoSchema.safeParse(x).success
