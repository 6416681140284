import { z } from 'zod'

import { logger } from '@hcr/utils'

interface GetJsonOptions<T extends z.ZodTypeAny> {
  headers?: HeadersInit
  schema: T
  url: string
}

export const getJson = async <T extends z.ZodTypeAny>({
  headers = {},
  schema,
  url,
}: GetJsonOptions<T>): Promise<z.TypeOf<T>> => {
  const response = await fetch(url, {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    method: 'GET',
    mode: 'cors',
  })

  if (!response.ok) {
    throw new Error(`Response status was not ok (${response.status} ${response.statusText})!`)
  }

  try {
    const json = await response.json()
    return schema.parse(json)
  } catch (error) {
    // Note: customZodError will be redundant when Zod fixes this issue: https://github.com/colinhacks/zod/issues/3516
    if (error instanceof z.ZodError) {
      const customZodError = new Error(error.message)
      customZodError.name = `ZodError: ${response.url}`
      logger.error(customZodError)
    }
    throw new Error('Response body was not parseable!')
  }
}
