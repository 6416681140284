import { Box, Divider, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, rotateYCss, UNICODE, unit } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { SvgLogoHolidayClub } from '../../../../../../assets'
import { SENTRY_CONFIG } from '../../../../../../configs'
import { useUserDetails } from '../../../../../../contexts'

import { OwnerCardLoading } from './OwnerCardLoading'

interface OwnerCardVariant {
  dark: string
  hasBadge: boolean
  light: string
  name: string
}

export const OwnerCard: FC = () => {
  const { t } = useTranslation()

  const userDetails = useUserDetails()

  const variant = useMemo<OwnerCardVariant>(() => {
    if (userDetails.data?.is_villas_owner) {
      return {
        dark: COLORS.ruoho[500],
        hasBadge: true,
        light: COLORS.ruoho['20%'],
        name: t('owner.villas'),
      }
    }

    if (userDetails.data?.is_active_gold_owner) {
      return {
        dark: COLORS.kaarna['60%'],
        hasBadge: true,
        light: COLORS.vaalea['60%'],
        name: t('owner.gold'),
      }
    }

    return {
      dark: COLORS.kaarna['60%'],
      hasBadge: false,
      light: COLORS.white,
      name: 'H',
    }
  }, [t, userDetails.data?.is_active_gold_owner, userDetails.data?.is_villas_owner])

  if (userDetails.isSuccess && isNotUndefined(userDetails.data)) {
    return (
      <Box position='relative' marginX={unit(5)} borderRadius={unit(1.5)} bgcolor={variant.light}>
        <BackgroundLetter color={variant.dark} css={rotateYCss}>
          {variant.name.at(0)}
        </BackgroundLetter>
        <Box position='relative' padding={unit(4)} textAlign='center'>
          <Stack direction='row' alignItems='center' justifyContent={variant.hasBadge ? 'space-between' : 'center'}>
            <SvgLogoHolidayClub height={unit(2.5)} fill={COLORS.black} />
            {variant.hasBadge && (
              <Box bgcolor={variant.dark} color={COLORS.white} borderRadius={unit(1)} paddingX={unit(1.5)}>
                <Typography variant='titleExtraSmall' textTransform='uppercase'>
                  {variant.name}
                </Typography>
              </Box>
            )}
          </Stack>
          <Stack gap={unit(4)} paddingTop={unit(7)} paddingX={unit(3)} paddingBottom={unit(3)}>
            <Title color={variant.dark}>{t('owner.owner-card')}</Title>
            <Divider />
            <Stack gap={unit(1)}>
              <Typography className={SENTRY_CONFIG.privacy.className} variant='titleM'>
                {userDetails.data.first_name} {userDetails.data.last_name}
              </Typography>
              <Typography className={SENTRY_CONFIG.privacy.className} variant='meta'>
                {userDetails.data.customer_id ?? UNICODE.nbsp}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    )
  }

  return <OwnerCardLoading />
}

const BackgroundLetter = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Moret', serif;
  font-size: ${unit(70)};
  font-weight: bold;
  text-align: center;
  user-select: none;
  opacity: 0.08;
`

const Title = styled(Box)`
  font-family: 'Moret', serif;
  font-size: ${unit(10)};
  font-weight: bold;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: ${unit(8)};
  }

  ${props => props.theme.breakpoints.down(350)} {
    font-size: ${unit(6.5)};
    word-break: break-all;
  }
`
