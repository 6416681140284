import hash from 'object-hash'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { hasPetFee, type PackageTicketDto } from '@hcr/api/consumer'
import { isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { LocaleNamespace, Path } from '../../../../../models'
import {
  createAccommodationGuestsString,
  createPackageTicketValidityString,
  createSingleTicketNameString,
  to,
} from '../../../../../utils'

import { TicketPreview } from './TicketPreview'
import { TicketPreviewLoading } from './TicketPreviewLoading'

interface TicketPreviewPackageProps {
  ticket: PackageTicketDto
}

export const TicketPreviewPackage: FC<TicketPreviewPackageProps> = ({ ticket }) => {
  const { t } = useTranslation()
  const {
    data: { accommodation },
    ...holiday
  } = useHoliday()

  if (holiday.isError) {
    return null
  }

  if (holiday.isSuccess && isNotUndefined(accommodation)) {
    return (
      <TicketPreview
        key={hash(['package', ticket.category_id, ticket.status])}
        guests={hasPetFee(ticket.category_id) ? undefined : createAccommodationGuestsString(accommodation)}
        link={to([
          Path.TicketDetailsPackage,
          { categoryId: ticket.category_id },
          { accommodationId: String(accommodation.accommodation_id) },
        ])}
        name={createSingleTicketNameString({ category_id: ticket.category_id, timeslot: null })}
        status={ticket.status}
        type={t('package', { ns: LocaleNamespace.TicketType })}
        validity={createPackageTicketValidityString(ticket.status)}
      />
    )
  }

  return <TicketPreviewLoading />
}
