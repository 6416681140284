import { useIsAuthenticated } from '@azure/msal-react'
import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import type { AccommodationDto } from '@hcr/api/consumer'
import type { DestinationDto } from '@hcr/api/optimizely'
import { find, flow, getPropertyValue, head, isNotNull, isNotUndefined, isUndefined, logger, sort } from '@hcr/utils'

import {
  useAccommodationDetailsQuery,
  useAccommodationsQuery,
  useActivitiesQuery,
  useDestinationDetailsQuery,
  useDestinationsQuery,
  useIdToken,
  useSingleTicketsQuery,
} from '../hooks'
import type { UseCompoundQueryResult } from '../models'
import { compareDates, isBookingIn, isDestinationOf } from '../utils'

interface Holiday {
  accommodation: AccommodationDto | undefined // Consumer API
  destination: DestinationDto | undefined // Optimizely API
}

const DEFAULT_HOLIDAY: Holiday = {
  accommodation: undefined,
  destination: undefined,
}

interface HolidayContext extends UseCompoundQueryResult<Holiday> {
  setAccommodation: (accommodation: AccommodationDto) => void
  setDestination: (destination: DestinationDto) => void
}

const HolidayContext = createContext<HolidayContext>({
  data: DEFAULT_HOLIDAY,
  isError: false,
  isPending: true,
  isSuccess: false,
  setAccommodation: () => void undefined,
  setDestination: () => void undefined,
})

enum TRIGGER {
  INIT,
  ACCOMMODATION,
  DESTINATION,
  NONE,
}

type HolidayProviderProps = PropsWithChildren

export const HolidayProvider: FC<HolidayProviderProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()

  const [trigger, setTrigger] = useState<TRIGGER>(TRIGGER.INIT)
  const [accommodation, setAccommodation] = useState<AccommodationDto | undefined>(DEFAULT_HOLIDAY.accommodation)
  const [destination, setDestination] = useState<DestinationDto | undefined>(DEFAULT_HOLIDAY.destination)

  const setAccommodationTrigger = (accommodation: AccommodationDto) => {
    setAccommodation(accommodation)
    setDestination(undefined)
    setTrigger(TRIGGER.ACCOMMODATION)
  }

  const setDestinationTrigger = (destination: DestinationDto) => {
    setAccommodation(undefined)
    setDestination(destination)
    setTrigger(TRIGGER.DESTINATION)
  }

  const clearTrigger = () => {
    setAccommodation(undefined)
    setDestination(undefined)
    setTrigger(TRIGGER.NONE)
  }

  const earliestAccommodation = useEarliestAccommodation()
  const firstTicketDestination = useFirstTicketDestination()

  const accommodationIn = useAccommodationIn(destination?.destinationId ?? null)
  const destinationOf = useDestinationOf(accommodation?.accommodation_id ?? null)

  // Handles TRIGGER.INIT
  useEffect(() => {
    if (trigger === TRIGGER.INIT) {
      if (isAuthenticated && earliestAccommodation.isSuccess && firstTicketDestination.isSuccess) {
        if (isNotUndefined(earliestAccommodation.data)) {
          return void setAccommodationTrigger(earliestAccommodation.data)
        }

        if (isNotUndefined(firstTicketDestination.data)) {
          return void setDestinationTrigger(firstTicketDestination.data)
        }

        return void clearTrigger()
      }
    } else if (trigger === TRIGGER.ACCOMMODATION) {
      if (
        isAuthenticated &&
        earliestAccommodation.isSuccess &&
        isNotUndefined(earliestAccommodation.data) &&
        destinationOf.isSuccess &&
        isUndefined(destinationOf.data)
      ) {
        logger.warn(
          `Destination details are not available for resort_id: ${earliestAccommodation.data?.resort_id}, check if provided mokkiId or hotelId are correct`
        )

        return void clearTrigger()
      }
    }
  }, [
    destinationOf.data,
    destinationOf.isSuccess,
    earliestAccommodation.data,
    earliestAccommodation.isSuccess,
    firstTicketDestination.data,
    firstTicketDestination.isSuccess,
    isAuthenticated,
    trigger,
  ])

  // Handles TRIGGER.ACCOMMODATION & TRIGGER.DESTINATION
  const data = useMemo<Holiday>(() => {
    if (isAuthenticated) {
      if (trigger === TRIGGER.ACCOMMODATION && destinationOf.isSuccess && isNotUndefined(destinationOf.data)) {
        return { accommodation, destination: destinationOf.data }
      }

      if (trigger === TRIGGER.DESTINATION && accommodationIn.isSuccess && isNotUndefined(accommodationIn.data)) {
        return { accommodation: accommodationIn.data, destination }
      }
    }

    return { accommodation, destination }
  }, [
    accommodation,
    accommodationIn.data,
    accommodationIn.isSuccess,
    destination,
    destinationOf.data,
    destinationOf.isSuccess,
    isAuthenticated,
    trigger,
  ])

  return (
    <HolidayContext.Provider
      value={{
        data,
        isError:
          isAuthenticated &&
          ((trigger === TRIGGER.INIT && earliestAccommodation.isError) ||
            (trigger === TRIGGER.INIT && firstTicketDestination.isError) ||
            (trigger === TRIGGER.ACCOMMODATION && destinationOf.isError) ||
            (trigger === TRIGGER.ACCOMMODATION && destinationOf.isSuccess && isUndefined(destinationOf.data)) ||
            (trigger === TRIGGER.DESTINATION && accommodationIn.isError)),
        isPending:
          isAuthenticated &&
          ((trigger === TRIGGER.INIT && earliestAccommodation.isPending) ||
            (trigger === TRIGGER.INIT && firstTicketDestination.isPending) ||
            (trigger === TRIGGER.ACCOMMODATION && destinationOf.isPending) ||
            (trigger === TRIGGER.DESTINATION && accommodationIn.isPending)),
        isSuccess:
          !isAuthenticated ||
          (trigger === TRIGGER.ACCOMMODATION && destinationOf.isSuccess) ||
          (trigger === TRIGGER.DESTINATION && accommodationIn.isSuccess) ||
          trigger === TRIGGER.NONE,
        setAccommodation: setAccommodationTrigger,
        setDestination: setDestinationTrigger,
      }}
    >
      {children}
    </HolidayContext.Provider>
  )
}

export const useHoliday = (): HolidayContext => {
  const context = useContext(HolidayContext)

  if (isUndefined(context)) {
    throw new Error('useHoliday must be used within HolidayProvider')
  }

  return context
}

const useEarliestAccommodation = () => {
  const idToken = useIdToken()

  return useAccommodationsQuery(
    { idToken: String(idToken) },
    {
      select: flow(sort({ comparer: compareDates, asc: getPropertyValue('start_date') }), head),
      enabled: isNotNull(idToken),
    }
  )
}

const useFirstTicketDestination = (): UseCompoundQueryResult<DestinationDto | undefined> => {
  const idToken = useIdToken()

  const singleTicket = useSingleTicketsQuery(
    { idToken: String(idToken) },
    {
      select: head,
      enabled: isNotNull(idToken),
    }
  )

  const activity = useActivitiesQuery(
    { idToken: String(idToken) },
    {
      select: head,
      enabled: isNotNull(idToken),
    }
  )

  const ticket = singleTicket.data || activity.data

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(ticket))),
    enabled: singleTicket.isSuccess,
  })

  return {
    data: destination.data,
    isError: singleTicket.isError || destination.isError || activity.isError,
    isPending:
      singleTicket.isPending ||
      (singleTicket.isSuccess && destination.isPending) ||
      activity.isPending ||
      (activity.isSuccess && activity.isPending),
    isSuccess: singleTicket.isSuccess && destination.isSuccess && activity.isSuccess,
  }
}

const useAccommodationIn = (destinationId: string | null): UseCompoundQueryResult<AccommodationDto | undefined> => {
  const idToken = useIdToken()

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(destinationId) },
    { enabled: isNotNull(destinationId) }
  )

  const accommodation = useAccommodationsQuery(
    { idToken: String(idToken) },
    {
      select: flow(
        sort({ comparer: compareDates, asc: getPropertyValue('start_date') }),
        find(isBookingIn(destinationDetails.data))
      ),
      enabled: isNotNull(idToken) && destinationDetails.isSuccess,
    }
  )

  return {
    data: accommodation.data,
    isError: destinationDetails.isError || accommodation.isError,
    isPending: destinationDetails.isPending || (destinationDetails.isSuccess && accommodation.isPending),
    isSuccess: destinationDetails.isSuccess && accommodation.isSuccess,
  }
}

const useDestinationOf = (accommodationId: number | null): UseCompoundQueryResult<DestinationDto | undefined> => {
  const idToken = useIdToken()

  const accommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(accommodationId) },
    { enabled: isNotNull(idToken) && isNotNull(accommodationId) }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(accommodation.data))),
    enabled: accommodation.isSuccess,
  })

  return {
    data: destination.data,
    isError: accommodation.isError || destination.isError,
    isPending: accommodation.isPending || (accommodation.isSuccess && destination.isPending),
    isSuccess: accommodation.isSuccess && destination.isSuccess,
  }
}
